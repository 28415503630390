/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-20 09:40:01
 * @LastEditTime: 2020-05-08 13:53:20
 */

const HOST_BASIC = '';
// 动态模板的详情页面的图文中的图片地址显示问题
export const IMG_SUANBANYUN = 'http://suanbanyun.com/jepaas/document/preview';

// 商城首页 商品列表数据
export const POST_GET_PRODUCT_LIST = `${HOST_BASIC}/jepaas/product/loadProductInfo`;
// 商品列表数据，包含订单够买信息
export const POST_GET_PRODUCT_LIST_ORDER = `${HOST_BASIC}/jepaas/product/loadVipProductInfo`;
// 获取增强组件的 配置信息
export const POST_GET_CMP_CONFIG = `${HOST_BASIC}/jepaas/product/getComponentDetail`;
// 通过用户id获取他的商业证书
export const POST_LOAD_CERTIFICATE_BY_USER_ID = `${HOST_BASIC}/jepaas/product/loadCertificateByUserId`;
// 下单
export const POST_ADD_ORDER = `${HOST_BASIC}/jepaas/order/addOrder`;
// 支付
export const POST_PLACE_PAY = `${HOST_BASIC}/jepaas/order/placePay`;
// 检查支付状态
export const POST_CHECK_ORDER_STATUS = `${HOST_BASIC}/jepaas/order/checkOrderStatus`;
// 已购订单的订单取消
export const POST_ORDER_REMOVE = `${HOST_BASIC}/jepaas/order/cancelOrder`;
// 动态获取支付按钮
export const POST_LOAD_PAY_ORDER_STATUS = `${HOST_BASIC}/jepaas/order/loadPayOrderStatus`;
// 获取白条权限或者额度
export const POST_GET_WHITE_BAR_INFO = `${HOST_BASIC}/jepaas/whiteBar/getWhiteBarInfo`;
// 获取商业证书的密文信息
export const POST_ENCRYPT_SYZS = `${HOST_BASIC}/jepaas/copyrightAudit/getCiphertext`;
// 通过商品ID获取资源资料/附件 配置信息
export const POST_RESOURCE_DATA = `${HOST_BASIC}/jepaas/product/loadResourceData`;
// 动态获取banner图信息
export const POST_GET_BANNER = `${HOST_BASIC}/jepaas/product/loadbannerInfo`;
// 获取打赏的表格信息
export const POST_GET_DS_LIST = `${HOST_BASIC}/jepaas/product/loadRewardInfo`;
// 获取销售人员列表
export const POST_GET_SALESMAN_INFO = `${HOST_BASIC}/jepaas/salesman/getSalesmanInfo`;
// 校验证书组件是否该买
export const POST_CHECK_PLUGIN = `${HOST_BASIC}/jepaas/order/checkPlugin`;
