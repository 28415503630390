/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-20 09:40:01
 * @LastEditTime: 2020-04-27 09:52:38
 */

import fetch from '@/assets/js/ajax';
import {
  POST_GET_PRODUCT_LIST_ORDER,
  POST_GET_PRODUCT_LIST,
  POST_GET_CMP_CONFIG,
  POST_LOAD_CERTIFICATE_BY_USER_ID,
  POST_ADD_ORDER,
  POST_PLACE_PAY,
  POST_CHECK_ORDER_STATUS,
  POST_ORDER_REMOVE,
  POST_LOAD_PAY_ORDER_STATUS,
  POST_GET_WHITE_BAR_INFO,
  POST_ENCRYPT_SYZS,
  POST_RESOURCE_DATA,
  POST_GET_BANNER,
  POST_GET_DS_LIST,
  POST_GET_SALESMAN_INFO,
  POST_CHECK_PLUGIN,
} from './api';

/**
 * 校验证书是否购买过组件
 * @param {*} param
 */
export function getDsCheckPlugin(param) {
  return fetch({ url: POST_CHECK_PLUGIN, data: param })
    .then(result => result.data)
    .catch();
}
/**
 * 获取打赏的订单列表
 * @param {*} param
 */
export function getDsTable(param) {
  return fetch({ url: POST_GET_DS_LIST, data: param })
    .then(result => result.data)
    .catch();
}
/**
 *  通过商品ID获取资源资料/附件 配置信息
 * @param {*} param
 */
export function getResourceData(param) {
  return fetch({ url: POST_RESOURCE_DATA, data: param })
    .then(result => result.data)
    .catch();
}
/*
 * 商城首页 商品列表数据
 */
export function getProductList(param) {
  return fetch({ url: POST_GET_PRODUCT_LIST, data: param })
    .then(result => result.data)
    .catch();
}
/**
 * 获得商品列表，商品编码逗号隔开
 * @param {*} param
 */
export function getProductList4order(param) {
  return fetch({ url: POST_GET_PRODUCT_LIST_ORDER, data: param })
    .then(result => result.data)
    .catch();
}
/*
 * 增强组件 商品配置信息
 */
export function getCmpConfig(param) {
  return fetch({ url: POST_GET_CMP_CONFIG, data: param })
    .then(result => result.data)
    .catch();
}
/*
 * 通过用户id获取他的商业证书
 */
export function loadCertificateByUserId(param) {
  return fetch({ url: POST_LOAD_CERTIFICATE_BY_USER_ID, data: param })
    .then(result => result.data)
    .catch();
}
/*
 * 下单
 */
export function addOrder(param) {
  return fetch({ url: POST_ADD_ORDER, data: param })
    .then(result => result.data)
    .catch();
}
/*
 * 支付
 */
export function startPay(param) {
  return fetch({ url: POST_PLACE_PAY, data: param })
    .then(result => result.data)
    .catch();
}
/*
 * 检查支付状态
 */
export function checkStatus(param) {
  return fetch({ url: POST_CHECK_ORDER_STATUS, data: param })
    .then(result => result.data)
    .catch();
}
/*
 * 取消订单
 */
export function removeOrder(param) {
  return fetch({ url: POST_ORDER_REMOVE, data: param })
    .then(result => result.data)
    .catch();
}
// 动态获取支付按钮 POST_LOAD_PAY_ORDER_STATUS
export function loadBtns(param) {
  return fetch({ url: POST_LOAD_PAY_ORDER_STATUS, data: param })
    .then(result => result.data)
    .catch();
}

// 动态获取支付按钮 POST_LOAD_PAY_ORDER_STATUS
export function getIOU(param) {
  return fetch({ url: POST_GET_WHITE_BAR_INFO, data: param })
    .then(result => result.data)
    .catch();
}
// 获取商业证书加密的密文
export function getEncrypt(param) {
  return fetch({ url: POST_ENCRYPT_SYZS, data: param })
    .then(result => result.data)
    .catch();
}

// 动态获取banner图信息
export function getAdvertising(param) {
  return fetch({ url: POST_GET_BANNER, data: param })
    .then(result => result.data)
    .catch();
}
/**
 * @name getSalesMan
 * @Descripttion: 获取销售人员列表
 * @param {Object}  param
 * @return {}
 */
export function getSalesMan(param) {
  return fetch({ url: POST_GET_SALESMAN_INFO, data: param })
    .then(result => result.data)
    .catch();
}
