<template>
  <div class="Jepaas-home">
    <Header />
    <div v-if="showDrainage" class="drainage-img">
      <i class="jeicon jeicon-error-circle" @click="hideDrainage"></i>
      <img src="@/assets/imgs/home/drainage.png" @click="skipToJecloud" alt="">
    </div>
    <swiper ref="homeSwiper" :options="swiperOption" class="swiper-no-swiping">
      <swiper-slide class="onePage">
        <div class="box_f">
          <!-- <img class="log_f" src="@/assets/imgs/home/logF.png" alt /> -->
          <div class="title_top">(十年沉淀 JEPaaS 低代码平台)</div>
          <div class="title_f">
            随心而动
            <span class="rc">•</span> 随意而用
          </div>
          <!-- <div class="text_f">
            <p style="margin: 0">
              JEPaaS是一套完整的软件开发平台，为企业信息化建设提供一揽子解决方案。
            </p>
            <p style="margin-top: 5px; margin-bottom: 0">
              这是一座宝库，等待您的探索！
            </p>
          </div>-->
          <div class="search_content">
            <div class="search_box">
              <el-input
                class="el_ipt"
                prefix-icon="el-icon-search"
                v-model="keyword"
                :placeholder="placeholder"
                @keyup.enter.native="hSearch"
                clearable
              ></el-input>
              <el-button class="el_btn" @click="hSearch">搜索.</el-button>
            </div>
            <div class="search_link">
              <span v-for="(item,idx) in linkList" :key="item.SSGLRMTJ_DBT">
                <span class="search_link_txt" @click="jumpToSchool(item)">{{item.SSGLRMTJ_DBT}}</span>
                <span class="search_link_line" v-show="idx<linkList.length-1">|</span>
              </span>
            </div>
          </div>
          <div class="link-data">
            <div
              v-for="item in linkData"
              :key="item.id"
              :style="{background: item.bg}"
              @click="linkDataGo(item.link)"
              class="single-item"
            >
              <div class="left">
                <img :src="item.icon" />
              </div>
              <div class="right">
                <p class="title-link">{{ item.title }}</p>
                <p class="text">{{ item.text }}</p>
              </div>
            </div>
          </div>
          <!-- <div class="bufCon_f">
            <div
              @click="goRouterPage('shop')"
              class="bufCon_f_btn"
              style="margin-right: 56px;"
            >
              进入商城
            </div>
            <div
              @click="openWindow('http://www.jedanao.com/')"
              class="bufCon_f_btn"
            >
              企业大脑
            </div>
            <div
              @click="openGroup('')"
              class="bufCon_f_btn"
            >
              入群交流
            </div>
          </div>-->
          <!-- <div class="bo_con">
            <img
              class="log_n"
              src="@/assets/imgs/home/bag_o1.png"
              alt=""
            >
            <img
              class="log_u"
              src="@/assets/imgs/home/bag_o2.png"
              alt=""
            >
          </div>-->
          <div class="bu_con">
            <div class="topBg_lcon">
              <img class src="@/assets/imgs/home/topBg_l.png" alt />
            </div>
            <div class="topBg_rcon">
              <img class src="@/assets/imgs/home/topBg_r.png" alt />
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="add-page-page1">
        <div class="home-content">
          <div class="tab-title">
            <span
              :class="{'tab-item':true,'active-tab':activeTab.title == item.title}"
              @click="activeTab = item"
              v-for="item in videoTabData"
              :key="item.title"
            >{{item.title}}</span>
          </div>
          <div class="vedio_wrp">
            <video
              ref="video"
              id="homevideo"
              muted="true"
              autoplay
              loop
              :src="activeTab.video"
              preload="auto"
              controls="controls"
              class="video"
            />
            <!-- <div
              v-if="vedioInit"
              class="mask init_mask"
              @click="videoPlay"
              @mouseover="isplayhover = true"
              @mouseout="isplayhover = false"
            >
              <img :src="home_vedio_init_bg" class="init_bg" />
              <img :src="isplayhover?playhover:play" alt class="play" />
            </div>
            <div
              v-show="videoMask == 'pause'"
              class="mask video_mask"
              @click="videoPlay"
              @mouseover="isplayhover = true"
              @mouseout="isplayhover = false"
            >
              <img :src="isplayhover?playhover:play" alt class="play" />
            </div>-->
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="add-page1">
        <div class="home-content">
          <div class="home-title">平台六大核心价值</div>
          <div class="pro-tabs">
            <div class="ttabs alltabs">
              <div @click="goPage('code')" class="everytab">
                <span class="tabimg">
                  <img src="@/assets/newHomeImg/home/1.svg" alt />
                </span>
                <div>低代码开发</div>
              </div>
              <div @click="goPage('data')" class="everytab">
                <span class="tabimg">
                  <img src="@/assets/newHomeImg/home/2.svg" alt />
                </span>
                <div>数字化中台</div>
              </div>
              <div @click="goPage('local')" class="everytab">
                <span class="tabimg">
                  <img src="@/assets/newHomeImg/home/3.svg" alt />
                </span>
                <div>国产化适配</div>
              </div>
            </div>
            <div class="btabs alltabs">
              <div @click="goPage('saas')" class="everytab">
                <span class="tabimg">
                  <img src="@/assets/newHomeImg/home/4.svg" alt />
                </span>
                <div>SaaS 搭建</div>
              </div>
              <div @click="goPage('phone')" class="everytab">
                <span class="tabimg">
                  <img src="@/assets/newHomeImg/home/5.svg" alt />
                </span>
                <div>微信、钉钉、飞书</div>
              </div>
              <div @click="goPage('api')" class="everytab">
                <span class="tabimg">
                  <img src="@/assets/newHomeImg/home/6.svg" alt />
                </span>
                <div>开放、对接、IOT</div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="fourPage">
        <div class="home-content">
          <div class="home-title">
            国产化软件适配
            <el-button
              @click="goPlatform('GCHRJSP')"
              class="learnMore"
              type="primary"
              round
              size="medium"
            >了解更多</el-button>
          </div>
          <img v-lazy="local" class="gchimg" alt />
        </div>
      </swiper-slide>
      <swiper-slide class="add-page2 hycp-page">
        <div class="home-content">
          <div class="home-title">
            JEPaaS之行业产品
            <el-button @click="goShop" class="learnMore" type="primary" round size="medium">前往商城</el-button>
          </div>
          <div class="cmps-box">
            <el-row :gutter="20">
              <el-col
                v-for="(hycp, _hycp) in hycpList"
                :key="_hycp"
                :span="6"
                :style="_hycp > 3 && 'margin-top: 40px' || 'margin-top: 0'"
              >
                <el-card>
                  <div @click="openDetail(hycp)" class="hycp_img">
                    <img
                      v-if="hycp.filekey"
                      v-lazy="'/jepaas/document/preview?fileKey=' + hycp.filekey"
                    />
                    <div class="hycp_content">
                      <div class="hycp_SPMC">{{ hycp.values.PRODUCT_SPMC }}</div>
                      <div class="hycp_JYSM">{{ hycp.values.PRODUCT_JYSM }}</div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="add-page2">
        <div class="home-content">
          <div class="home-title">
            JEPaaS之增强组件
            <el-button @click="goShop" class="learnMore" type="primary" round size="medium">前往商城</el-button>
          </div>
          <div class="cmps-box">
            <el-row :gutter="20">
              <el-col :span="12" style="margin-top: 0">
                <div @click="goZjPage('1')" class="cmps">
                  <img v-lazy="zj1" />
                </div>
                <img v-lazy="zj_logo" class="zj-logo" />
              </el-col>
              <el-col :span="12" style="margin-top: 0">
                <div @click="goZjPage('2')" class="cmps">
                  <img v-lazy="zj2" />
                </div>
                <img v-lazy="zj_logo" class="zj-logo" />
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <div @click="goZjPage('3')" class="cmps">
                  <img v-lazy="zj3" />
                </div>
                <img v-lazy="zj_logo" class="zj-logo" />
              </el-col>
              <el-col :span="12">
                <div @click="goZjPage('4')" class="cmps">
                  <img v-lazy="zj4" />
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <div @click="goZjPage('5')" class="cmps">
                  <img v-lazy="zj5" />
                </div>
              </el-col>
              <el-col :span="12">
                <div @click="goZjPage('6')" class="cmps">
                  <img v-lazy="zj6" />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="twoPage">
        <div class="home-content">
          <div class="home-title">
            低代码快速开发
            <el-button
              @click="goPlatform('DDMKSKF')"
              class="learnMore"
              type="primary"
              round
              size="medium"
            >了解更多</el-button>
          </div>
          <div class="je-tabs">
            <div
              v-for="(item, index) in codeTabs"
              @click="toggleImg(item.code)"
              :key="index"
              :class="{ 'tab-active animated fadeIn fast': activeCode == item.code}"
              class="je-tab"
            >{{ item.name }}</div>
          </div>
          <div class="activeimg-box">
            <img v-lazy="tabimgUrl" class="activeImg" alt />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="threePage">
        <div class="home-content">
          <div class="home-title">
            数字化中台工具
            <el-button
              @click="goPlatform('SZHZTGJ')"
              class="learnMore"
              type="primary"
              round
              size="medium"
            >了解更多</el-button>
          </div>
          <!-- <div class="home-sub-title">
            JEPaaS将原有分散的企业应用系统通过以通信服务、数据库操作、应用式配置中心、认证中心等在内的多种基础组件进行快速对接，基于多维内存数据库技术在数据架构层实现数据信息的汇总，形成标准化数据，通过统一数据实现所有相关业务系统的集中管理。
          </div>-->
          <img v-lazy="kj" class="dataimg" alt />
          <!-- <div class="playBtn">
            <el-button
              type="danger"
              plain
              class="videoBtn"
              round
              style="font-size: 16px"
              @click="goPlatform('SZHZTGJ', true)"
            >
              请前往观看视频
            </el-button>
          </div>-->
        </div>
      </swiper-slide>
      <swiper-slide class="fivePage">
        <div class="home-content saas-box">
          <div class="home-title">
            SaaS快速开发
            <el-button
              @click="goPlatform('SAAS')"
              class="learnMore"
              type="primary"
              round
              size="medium"
            >了解更多</el-button>
          </div>
          <img v-lazy="saas" class="saasimg" alt />
        </div>
      </swiper-slide>
      <swiper-slide class="sixPage">
        <div class="home-content">
          <div class="home-title">
            支持微信、钉钉、飞书、APP
            <el-button
              @click="goPlatform('MOBILE')"
              class="learnMore"
              type="primary"
              round
              size="medium"
            >了解更多</el-button>
          </div>

          <img v-lazy="mobile" class="cpimg" alt />
        </div>
      </swiper-slide>
      <swiper-slide class="sevenPage">
        <div class="home-content">
          <div class="home-title">
            完善的对外接口
            <el-button
              @click="goPlatform('API')"
              class="learnMore"
              type="primary"
              round
              size="medium"
            >了解更多</el-button>
          </div>
          <img v-lazy="api" class="apiimg" alt />
        </div>
      </swiper-slide>
      <swiper-slide class="eightPage">
        <div class="box_th home-content">
          <div class="home-title">适用场景及人群</div>
          <div class="box_con">
            <div v-for="item in dataTh" :key="item.id" class="syrqcard">
              <img :src="item.img" alt />
              <div>
                <div class="title">{{ item.title }}</div>
                <div class="data">{{ item.data }}</div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="lastPage">
        <div class="box_fi">
          <div class="title_fi home-text-center">我们的客户</div>
          <div class="img_box">
            <div class="img_data">
              <img v-lazy="company_1" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_2" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_3" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_4" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_5" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_6" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_7" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_8" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_9" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_10" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_11" alt />
            </div>
            <div class="img_data">
              <img v-lazy="company_12" alt />
            </div>
          </div>
        </div>
        <Footer />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { getProductList } from '@/pages/detail/actions/actions'
import { searchHome } from '@/pages/index/actions/actions'
import { isArray } from '@/util/util'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
// import { animateCSS } from '@/util/util';
import company_1 from '@/assets/imgs/home/company_1.png'
import company_2 from '@/assets/imgs/home/company_2.png'
import company_3 from '@/assets/imgs/home/company_3.png'
import company_4 from '@/assets/imgs/home/company_4.png'
import company_5 from '@/assets/imgs/home/company_5.png'
import company_6 from '@/assets/imgs/home/company_6.png'
import company_7 from '@/assets/newHomeImg/home/ht.png'
import company_8 from '@/assets/imgs/home/company_8.png'
import company_9 from '@/assets/imgs/home/company_9.png'
import company_10 from '@/assets/imgs/home/company_10.png'
import company_11 from '@/assets/imgs/home/company_11.png'
import company_12 from '@/assets/imgs/home/company_12.png'
import scene01 from '@/assets/imgs/home/scene01.png'
import scene02 from '@/assets/imgs/home/scene02.png'
import scene03 from '@/assets/imgs/home/scene03.png'
import scene04 from '@/assets/imgs/home/scene04.png'
import scene05 from '@/assets/imgs/home/scene05.png'
import scene06 from '@/assets/imgs/home/scene06.png'
import api from '@/assets/newHomeImg/home/api.svg'
import mobile from '@/assets/newHomeImg/home/phone.svg'
import code1 from '@/assets/newHomeImg/home/code1.svg'
import code2 from '@/assets/newHomeImg/home/code2.svg'
import code3 from '@/assets/newHomeImg/home/code3.svg'
import code4 from '@/assets/newHomeImg/home/code4.svg'
import code5 from '@/assets/newHomeImg/home/code5.svg'
import kj from '@/assets/newHomeImg/home/data.svg'
import local from '@/assets/newHomeImg/home/local.svg'
import saas from '@/assets/newHomeImg/home/saas.svg'
import zj1 from '@/assets/newHomeImg/home/zj1.png'
import zj2 from '@/assets/newHomeImg/home/zj2.png'
import zj3 from '@/assets/newHomeImg/home/zj3.png'
import zj4 from '@/assets/newHomeImg/home/zj4.png'
import zj5 from '@/assets/newHomeImg/home/zj5.png'
import zj6 from '@/assets/newHomeImg/home/zj6.png'
import zj_logo from '@/assets/newHomeImg/home/zj-logo.svg'
import home_vedio_init_bg from '@/assets/imgs/home/home_vedio_init_bg2.jpg'
import playhover from '@/assets/imgs/home/home_play_hover.png'
import play from '@/assets/imgs/home/home_play.png'

export default {
  name: 'HomePage',
  components: {
    Header,
    Footer
  },
  data() {
    const that = this
    return {
      showDrainage: true,
      placeholder: '',
      keyword: '',
      pageSize: '8',
      pageIndex: '0',
      vedioInit: true,
      isplayhover: false,
      home_vedio_init_bg,
      playhover,
      play,
      videoMask: 'pause', // 播放状态
      hycpList: [], // 行业产品的数据
      zj_logo,
      zj1,
      zj2,
      zj3,
      zj4,
      zj5,
      zj6,
      api,
      code1,
      code2,
      code3,
      code4,
      code5,
      mobile,
      tabimgUrl: code1,
      kj,
      local,
      saas,
      company_1,
      company_2,
      company_3,
      company_4,
      company_5,
      company_6,
      company_7,
      company_8,
      company_9,
      company_10,
      company_11,
      company_12,
      codeTabs: [
        { name: '平台架构', code: '1' },
        { name: '功能引擎', code: '2' },
        { name: '流程引擎', code: '3' },
        { name: 'BI引擎', code: '4' },
        { name: '脚本工具', code: '5' }
      ],
      activeCode: '1',
      swiperOption: {
        direction: 'vertical',
        mousewheel: true,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper 解决刷新后页面布局错乱
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper 解决刷新后页面布局错乱
        on: {
          // 监听滑动切换事件，返回swiper对象
          slideChange() {
            that.swiper.activeIndex == 0
              ? that.$store.commit('topChange', 0)
              : that.$store.commit('topChange', 1)
          }
        }
      },
      dataTh: [
        {
          id: 0,
          img: scene01,
          title: '软件提供商',
          data: '可大幅度快速降低时间和人工成本，并且支持SaaS模式'
        },
        {
          id: 1,
          img: scene04,
          title: '中大型或集团性企业信息化系统建设',
          data: '高效实现信息化解决方案，并能快速落地'
        },
        {
          id: 2,
          img: scene02,
          title: '小微企业/门店/学校日常办公工具搭建',
          data: '如OA办公、即时通讯、考勤行政管理等'
        },
        {
          id: 3,
          img: scene05,
          title: '中大型或集团性企业的阶段性信息化建设',
          data: '包括与原有系统对接，多种扩展接口完美兼顾个性与复杂业务'
        },
        {
          id: 4,
          img: scene03,
          title: '各中型企业各类系统搭建',
          data: '如采购系统、考试系统、报销系统、设备管理系统等'
        },
        {
          id: 5,
          img: scene06,
          title: '中大型或集团性企业战略性信息化建设',
          data: '支持大集团性公司战略信息化部署，尤其在整体信息化布局方面表现尤为出色'
        }
      ],
      linkData: [
        {
          id: 1,
          title: '进入商城',
          text: '按需定制您的专属JEPaaS',
          bg: '#FF7A45',
          icon: require('@/assets/svg/je-mall.svg'),
          link: 'shop'
        },
        {
          id: 2,
          title: '社区论坛',
          text: '经验分享、互动交流',
          bg: '#FB6345',
          icon: require('@/assets/svg/je-community.svg'),
          link: 'http://bbs.jepaas.com/'
        },
        {
          id: 3,
          title: '企业大脑',
          text: '您的一站式管理加速器',
          bg: '#FFA940',
          icon: require('@/assets/svg/elephant-q.svg'),
          link: 'http://www.jedanao.com/'
        },
        {
          id: 4,
          title: '入群交流',
          text: '技术大牛在线解答',
          bg: '#FC4A4A',
          icon: require('@/assets/svg/je-group.svg'),
          link: 'http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c'
        }
      ],

      activeTab: {
        title: '标准工作台',
        video:
          // 'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-work.mp4'
          'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-swork.mp4'
      },
      videoTabData: [
        {
          title: '标准工作台',
          video:
            'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-swork.mp4'
        },
        {
          title: '员工工作台',
          video:
            'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-work.mp4'
        },
        {
          title: '功能表单',
          video:
            'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-func.mp4'
        },
        {
          title: '流程引擎',
          video:
            'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-flow.mp4'
        },
        // {
        //   title: '手机应用',
        //   video:
        //     'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-phone.mp4'
        // },
        {
          title: '打印功能',
          video:
            'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-print.mp4'
        },
        {
          title: '脚本植入',
          video:
            'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-script.mp4'
        },
        {
          title: '案例展示',
          video:
            'https://platform-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-show.mp4'
        }
      ],
      linkList: []
    }
  },
  computed: {
    swiper() {
      return this.$refs.homeSwiper.swiper
    }
  },
  watch:{
    'activeTab.video':function(val) {
      if(this.activeTab.title=='员工工作台'||this.activeTab.title=='功能表单'||this.activeTab.title=='流程引擎') {
        document.getElementById('homevideo').playbackRate = 3
        document.getElementById('homevideo').defaultPlaybackRate = 3
      
      }else {
        document.getElementById('homevideo').playbackRate = 1
        document.getElementById('homevideo').defaultPlaybackRate = 1
      }
    }
  },
  created() {
    this.searchHome()
    this.getIndustryList()
  },

  mounted() {
    this.searchHome()
    // this.open();
    const that = this
    const myVideo = document.getElementsByClassName('video')[0]
    myVideo.addEventListener('play', () => {
      that.$set(that, 'videoMask', 'play')
    })

    myVideo.addEventListener('pause', () => {
      that.$set(that, 'videoMask', 'pause')
    })
  },
  beforeRouteLeave(to, from, next) {
    const nodeElement = document.getElementsByClassName('el-notification')[0]
    nodeElement && nodeElement.remove()
    next()
  },
  methods: {
    // 隐藏引流图片
    hideDrainage() {
      this.showDrainage = false
    },

    // 跳转到jecloud
    skipToJecloud() {
      window.open('https://jecloud.net/')
    },

    // 搜索
    hSearch() {
      // this.pageIndex = '1'
      // this.showTab = 'results'
      // this.searchQuery()
      this.$router.push({
        path: '/school',
        query: {
          showTab: 'results',
          keyword: this.keyword || this.placeholder,
          pageSize: '8',
          pageIndex: '0',
          record:'1'
        }
      })
    },

    searchHome() {
      searchHome().then((res) => {
        if (res.code == 1000) {
          this.linkList = res.obj.sslist
          this.placeholder = res.obj.rmList[Math.floor(10*Math.random())].SSGLRMTJ_BT
        }
      })
    },
    jump(url) {
      if (url) window.open(url)
    },
    jumpToSchool(item) {
      this.keyword = item.SSGLRMTJ_DBT
      this.hSearch()
    },
    // 点击播放按钮
    videoPlay() {
      this.vedioInit = false
      this.$refs.video.play()
    },
    // 链接跳转
    linkDataGo(str) {
      if (str === 'shop') {
        this.$router.push({
          path: str
        })
      } else {
        window.open(str)
      }
    },
    //       // <p class="wy-close" onclick="onClose()" ref="wyRef" id="close">关闭</p>
    open() {
      const notifyHTMl = `
      <div class="wy-notify-div">
        <p class="wy-title">企业大脑V3.0</p>
        <p class="wy-txt">您的一站式管理加速器</p>
        <div class="wy-san-jiao" />
      </div>
    `
      this.$notify({
        title: '',
        dangerouslyUseHTMLString: true,
        duration: 0,
        offset: 100,
        customClass: 'change-class',
        onClick() {
          window.open('http://www.jedanao.com/')
        },
        message: notifyHTMl
      })
    },
    // 获取行业产品的数据
    getIndustryList() {
      getProductList({ productType: 'HYCP', end: 8 }).then((result) => {
        this.hycpList = isArray(result.obj) ? result.obj : []
        this.hycpList.forEach((item) => {
          item.filekey =
            item.values &&
            item.values.PRODUCT_TB &&
            item.values.PRODUCT_TB.split('*')[1]
        })
      })
    },
    // 点击了解更多 跳转平台对应tab
    goPlatform(param, bool) {
      this.$router.push({
        name: 'platformPage',
        params: {
          activeName: param,
          play: bool
        }
      })
    },
    // swiper 切换到指定页
    goPage(code) {
      switch (code) {
        case 'code':
          this.swiper.slideTo(6, 1000, false)
          break
        case 'data':
          this.swiper.slideTo(7, 1000, false)
          break
        case 'local':
          this.swiper.slideTo(3, 1000, false)
          break
        case 'saas':
          this.swiper.slideTo(8, 1000, false)
          break
        case 'phone':
          this.swiper.slideTo(9, 1000, false)
          break
        case 'api':
          this.swiper.slideTo(10, 1000, false)
          break
        default:
          break
      }
    },
    // 点击增强组件块  跳转页面
    goZjPage(code) {
      switch (code) {
        case '1':
          window.open('/shop/jepaas-zqzj-0010.html')
          break
        case '2':
          window.open('/shop/jepaas-zqzj-0018.html')
          break
        case '3':
          window.open('/shop/jepaas-zqzj-0017.html')
          break
        case '4':
          window.open('/shop/jepaas-zqzj-0002.html')
          break
        case '5':
          window.open('/shop/jepaas-zqzj-0005.html')
          break
        case '6':
          window.open('/shop/jepaas-zqzj-0001.html')
          break
        default:
          break
      }
    },
    // 行业产品跳转组件详情
    openDetail(em) {
      const code = em.values.PRODUCT_SPBH.toLocaleLowerCase() // 商品编码
      const enableTpl = em.values.PRODUCT_SYMBSM || 1 // 是否启用模板链接
      const url = em.values.PRODUCT_XXLJ
      // if (this.target == 'shop') {
      //   window.open(url);
      // } else {
      //   window.open(url.substring(url.lastIndexOf('/') + 1));
      // }
      if (em.values.PRODUCT_LJDZ) {
        window.open(`${em.values.PRODUCT_LJDZ}`)
      }
      if (enableTpl == 1) {
        window.open(`shop/${code}.html`)
      } else {
        window.open(url.substring(url.lastIndexOf('/') + 1))
      }
    },
    // 切换代码开发图片
    toggleImg(code) {
      this.activeCode = code
      switch (code) {
        case '1':
          this.tabimgUrl = code1
          break
        case '2':
          this.tabimgUrl = code2
          break
        case '3':
          this.tabimgUrl = code3
          break
        case '4':
          this.tabimgUrl = code4
          break
        case '5':
          this.tabimgUrl = code5
          break
        default:
          break
      }
    },
    // 组件了解更多
    goShop() {
      this.$router.push({
        path: '/shop',
        name: 'shopPage',
        params: {
          act: 'ZQZJ'
        }
      })
    },
    // 进入商城
    goRouterPage(route) {
      if (!route.startsWith('/')) {
        route = `/${route}`
      }
      this.$router.push({
        path: route
      })
      // window.open(route);
    },
    openWindow(url) {
      if (!url) return
      window.open(url)
    },
    // 进入商圈
    openGroup(num) {
      !num &&
        window.open(
          'http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c'
        )
      // num && this.$router.push({
      //   path: '/promotion',
      // });
      num && window.open('/promotion')
    }
  }
}
</script>
<style lang="less">
.cmps-box {
  .el-card__body {
    padding: 0;
  }
}
</style>
<style lang="less" scoped>
.Jepaas-home {
  color: #3f3f3f;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .drainage-img {
      width: 11.5rem;
      position: fixed;
      right: 1.5625rem;
      top: 14.4rem;
      z-index: 999;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .jeicon-error-circle {
      position: absolute;
      right: 0.4rem;
      top: 0.6rem;
      color: #a9aba9;
    }
  .crown-jeicon {
    font-size: 26px;
    position: absolute;
    width: 100%;
    left: -110px;
    top: -15px;
    z-index: 2;
    color: #ffc421;
  }
  @keyframes shine {
    0% {
      left: 90%;
    }
    100% {
      left: 75%;
    }
  }
  .cuXiao_btnCss {
    position: absolute;
    top: 5px;
    width: 10px;
    background: linear-gradient(90deg, #fff);
    height: 60%;
    opacity: 0.5;
    transform: rotate(35deg);
    animation: shine 1s linear infinite;
  }
  .swiper-container,
  .swiper-wrapper {
    height: 100%;
    position: relative;
  }
  .home-content {
    text-align: center;
    width: 55rem;
    margin: 80px auto 0 auto;
    height: 100%;
    .tab-title {
      margin-top: 4rem;
      margin-bottom: 0.5rem;
      text-align: left;
      .tab-item {
        display: inline-block;
        margin-right: 2rem;
        padding: 0.4rem 0rem;
        border-radius: 0.5rem;
        // font-weight: bold;
        // color: #7f7f7f;
        cursor: pointer;
        font-size: 20px;
        &:hover {
          color: #fa6445;
        }
      }
      .tab-item:last-child {
        margin-right: 0;
      }
      .active-tab {
        // background-color: #f0f0f0;
        color: #fa6445;
      }
    }
    .home-title {
      padding-top: 50px;
      font-size: 30px;
      font-weight: bold;
      color: #3f3f3f;
      position: relative;
      .learnMore {
        position: absolute;
        right: 40px;
        bottom: 0;
      }
    }
    .home-sub-title {
      margin-top: 15px;
      font-size: 16px;
      text-align: center;
    }
  }
  .onePage {
    .box_f {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      overflow: hidden;
      .search_content {
        width: 37.8rem;
        margin: 1.8rem auto 0;
        .search_box {
          position: relative;
          width: 100%;
          .el_ipt {
            width: 80%;
            height: 2.8rem;
            vertical-align: top;
            // vertical-align: top;
            /deep/.el-input__inner {
              height: 2.8rem;
              border: 1px solid #eb4e4b;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              font-size: 0.8rem;
              padding-left: 2.2rem;
            }
            /deep/.el-input__icon {
              margin-left: 0.6rem;
              line-height: 2.8;
              font-size: 1rem;
              color: #7b7b7a;
              font-weight: 600;
            }
          }
          .el_btn {
            width: 20%;
            height: 2.8rem;
            border-top: 1px;
            border-bottom: 1px;
            margin-left: -0.1rem;
            // vertical-align: middle;
            color: #fff;
            background-color: #ff343f;
            font-size: 1.1rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
        .search_link {
          width: 100%;
          text-align: left;
          margin: 8px 0 0 3px;
          font-size: 0.8rem;
          color: #7f7f7f;
          .search_link_txt {
            cursor: pointer;
          }
          .search_link_line {
            margin: 0 0.4rem;
          }
        }
      }

      .bo_con {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0px;
        z-index: 10;
        .log_n {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 110%;
          height: 171px;
          animation: slideshow 4s linear infinite;
        }
        .log_u {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 110%;
          height: 230px;
          animation: slideshow2 4s linear infinite;
        }
        @keyframes slideshow {
          0% {
            -webkit-transform: translate(0px, 0px;);
          }
          25% {
            -webkit-transform: translate(-10px, 10px);
          }
          50% {
            -webkit-transform: translate(-25px, 20px);
          }
          75% {
            -webkit-transform: translate(-10px, 10px);
          }
          100% {
            -webkit-transform: translate(0px, 0px);
          }
        }
        @keyframes slideshow2 {
          0% {
            -webkit-transform: translate(0px, 20px;);
          }
          25% {
            -webkit-transform: translate(-10px, 10px);
          }
          50% {
            -webkit-transform: translate(-25px, 0px);
          }
          75% {
            -webkit-transform: translate(-10px, 10px);
          }
          100% {
            -webkit-transform: translate(0px, 20px);
          }
        }
      }
      .bu_con {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .topBg_lcon {
          height: 1000px;
          width: 700px;
          img {
            width: 100%;
            height: 95%;
          }
        }
        .topBg_rcon {
          height: 1000px;
          width: 651px;
          img {
            width: 100%;
            height: 107%;
          }
        }
      }
      .log_f {
        width: 223px;
        height: 223px;
        margin-top: 7%;
        z-index: 10;
      }
      .title_top {
        font-size: 1.5rem;
        font-weight: bold;
        color: #d9001b;
        margin-top: 15%;
        margin-bottom: 2%;
        z-index: 10;
      }
      .title_f {
        // font-size: 60px;
        font-size: 4.2rem;
        font-weight: bold;
        color: #333;
        line-height: 70px;
        z-index: 10;
        .rc {
          color: #de0221;
        }
      }
      .text_f {
        font-size: 26px;
        padding: 50px 0;
      }
      .link-data {
        display: flex;
        width: 100%;
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        .single-item {
          flex: 1;
          color: #fff;
          display: flex;
          cursor: pointer;
          height: 200px;
          align-items: center;
          padding: 0 50px 0 50px;
          transition: all 0.1s linear;
          &:hover {
            height: 210px;
          }
        }
        .left {
          // margin-right: 30px;
          height: 100px;
          img {
            width: 100px;
            // font-size: 50px;
            // margin-top: 10px;
          }
        }
        .right {
          .title-link {
            font-size: 30px;
            font-weight: bold;
            text-align: left;
            line-height: 0;
          }
          .text {
            line-height: 0;
            padding-top: 20px;
          }
        }
      }
      .bufCon_f {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        margin: 0 auto;
        .bufCon_f_img {
          width: auto;
          height: 90px;
          margin-top: 10px;
          cursor: pointer;
        }
        .bufCon_f_btn {
          width: 220px;
          height: 60px;
          border-radius: 6px;
          text-align: center;
          line-height: 60px;
          font-size: 20px;
          font-weight: 400;
          cursor: pointer;
        }
        .bufCon_f_btn:nth-child(1) {
          background: #f15442;
          color: #fff;
          transition: background 0.7s;
        }
        .bufCon_f_btn:nth-child(1):hover {
          background: #da332e;
        }
        .bufCon_f_btn:nth-child(2) {
          background: rgba(255, 255, 255, 1);
          border: 2px solid #da332e;
          color: #da332e;
          transition: background 0.7s, color 0.7s;
        }
        .bufCon_f_btn:nth-child(2):hover {
          background: #da332e;
          color: #fff;
        }
        .bufCon_f_btn:nth-child(3) {
          background: rgba(255, 255, 255, 1);
          border: 2px solid rgba(0, 0, 0, 1);
          color: rgba(0, 0, 0, 1);
          transition: background 0.7s, color 0.7s;
        }
        .bufCon_f_btn:nth-child(3):hover {
          background: rgba(0, 0, 0, 1);
          color: #fff;
        }
      }
    }
  }
  // .add-page-page1 {
  // }
  .add-page1 {
    background-image: url('../../../assets/newHomeImg/home/bgright.png');
    background-size: 100% 100%;
    .pro-tabs {
      width: 100%;
      box-sizing: border-box;
      margin-top: 30px;
      .alltabs {
        display: flex;
        justify-content: center;
        align-items: center;
        .everytab {
          width: 390px;
          font-size: 26px;
          color: #fff;
          font-weight: 600;
          background: linear-gradient(
            215deg,
            rgba(255, 142, 140, 1) 0%,
            rgba(241, 79, 79, 1) 100%
          );
          border-radius: 6px;
          text-align: center;
          padding: 65px;
          box-sizing: border-box;
          cursor: pointer;
          & > div {
            margin-top: 20px;
          }
          .tabimg {
            display: inline-block;
            width: 155px;
            font-size: 0;
            vertical-align: middle;
            transition: all 0.6s;
            img {
              vertical-align: middle;
              width: 100%;
            }
            &:hover {
              /*放大倍数*/
              transform: scale(1.2);
            }
          }
          &:nth-child(2n) {
            margin: 0 40px;
          }
          &:nth-child(2) {
            background: linear-gradient(
              203deg,
              rgba(253, 169, 113, 1) 0%,
              rgba(246, 88, 17, 1) 100%
            );
          }
          &:nth-child(3) {
            background: linear-gradient(
              163deg,
              rgba(255, 224, 133, 1) 0%,
              rgba(249, 151, 8, 1) 100%
            );
          }
        }
      }
      .btabs {
        margin-top: 40px;
        .everytab {
          background: linear-gradient(
            163deg,
            rgba(255, 224, 133, 1) 0%,
            rgba(249, 151, 8, 1) 100%
          );
          &:nth-child(2) {
            background: linear-gradient(
              215deg,
              rgba(255, 142, 140, 1) 0%,
              rgba(241, 79, 79, 1) 100%
            );
          }
          &:nth-child(3) {
            background: linear-gradient(
              203deg,
              rgba(253, 169, 113, 1) 0%,
              rgba(246, 88, 17, 1) 100%
            );
          }
        }
      }
    }
  }
  .twoPage {
    background-image: url('../../../assets/newHomeImg/home/bgleft.png');
    background-size: 100% 100%;
    .je-tabs {
      margin-top: 30px;
      width: 100%;
      background-color: #ececec;
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      .je-tab {
        flex: 1;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        font-size: 20px;
        &:hover {
          color: #fff;
          font-weight: 700;
          background: #ff3041;
        }
        &.tab-active {
          color: #fff;
          font-weight: 700;
          background: #ff3041;
        }
      }
    }
    .activeimg-box {
      width: 100%;
      max-height: 600px;
      position: relative;
      border-radius: 10px;
      margin-top: 30px;
      font-size: 0;
      overflow: hidden;
      box-shadow: 0px -2px 19px 4px rgba(83, 83, 83, 0.2);
      .activeImg {
        /* max-height: 560px;
        min-height: 560px; */
        width: 100%;
        /* @media screen  and (max-height: 768PX) {
          max-height: 500px;
          min-height: 500px;
        } */
      }
      .tagimg {
        position: absolute;
        width: 150px;
      }
      .tagimg1 {
        top: 40px;
        right: 305px;
      }
      .tagimg2 {
        top: 80px;
        right: 365px;
      }
      .tagimg3 {
        top: 80px;
        left: 370px;
      }
      .tagimg4 {
        top: 98px;
        right: 280px;
      }
    }
  }
  .threePage {
    background-image: url('../../../assets/newHomeImg/home/bgright.png');
    background-size: 100% 100%;
    .dataimg {
      max-height: 620px;
      min-height: 620px;
      margin-top: 10px;
      @media screen and (max-height: 768px) {
        max-height: 560px;
        min-height: 560px;
      }
    }
    .playBtn {
      margin-top: 30px;
      .el-button {
        padding: 13px 50px;
        border-radius: 25px;
        background-color: #f56c6c;
        color: #fff;
        &:hover {
          background-color: #fef0f0;
          color: #f56c6c;
        }
      }
    }
  }
  .fourPage {
    background-image: url('../../../assets/newHomeImg/home/bgleft.png');
    background-size: 100% 100%;
    .gchimg {
      width: 100%;
      margin-top: 80px;
      /* @media screen  and (max-height: 768PX) {
        max-height: 640px;
        min-height: 640px;
      } */
    }
  }
  .fivePage {
    background-image: url('../../../assets/newHomeImg/home/bgright.png');
    background-size: 100% 100%;
    .saasimg {
      max-height: 660px;
      min-height: 660px;
      margin-top: 30px;
      @media screen and (max-height: 768px) {
        max-height: 600px;
        min-height: 600px;
      }
    }
  }
  .sixPage {
    background-image: url('../../../assets/newHomeImg/home/bgleft.png');
    background-size: 100% 100%;
    overflow: hidden;
    font-size: 0;
    .cpimg {
      max-height: 620px;
      min-height: 620px;
      margin-top: 30px;
      @media screen and (max-height: 768px) {
        max-height: 600px;
        min-height: 600px;
      }
    }
  }
  .sevenPage {
    background-image: url('../../../assets/newHomeImg/home/bgright.png');
    background-size: 100% 100%;
    box-sizing: border-box;
    .apiimg {
      width: 100%;
      margin-top: 130px;
    }
  }
  .hycp-page {
    background-image: url('../../../assets/newHomeImg/home/bgleft.png') !important;
    .home-content {
      width: 1300px !important;
    }
  }
  .add-page2 {
    background-image: url('../../../assets/newHomeImg/home/bgright.png');
    background-size: 100% 100%;
    .cmps-box {
      margin-top: 40px;
      .hycp_img {
        position: relative;
        width: 100%;
        height: 260px;
        transition: 0.2s ease-in-out all;
        &:hover {
          cursor: pointer;
          .hycp_content {
            // transition: 3s;
            height: 120px;
            // animation: gt 0.2s linear;
            // animation-fill-mode: forwards;
            // transition: all 1s linear;
          }
        }
        // background: red;
        img {
          width: 100%;
          height: auto;
        }
        .hycp_content {
          height: 100px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background: white;
          transition: 0.2s height ease;
          .hycp_SPMC {
            font-weight: 600;
            font-size: 18px;
            text-align: left;
            padding: 15px;
          }
          .hycp_JYSM {
            font-size: 16px;
            text-align: left;
            padding-left: 15px;
            padding-right: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .el-row {
        margin: 0 !important;
      }
      .el-col-12 {
        margin-top: 50px;
        width: 600px;
        height: 180px;
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
        box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.23);
        border-radius: 10px;
        background-color: #fff;
        position: relative;
        .cmps {
          cursor: pointer;
          height: 160px;
          border-radius: 6px;
          overflow: hidden;
          img {
            height: 100%;
          }
        }
        .zj-logo {
          position: absolute;
          top: 0;
          right: 0;
        }
        &:last-child {
          margin-left: 50px;
        }
        &:hover {
          box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.33);
        }
      }
    }
  }
  .eightPage {
    background-image: url('../../../assets/newHomeImg/home/bgright.png');
    background-size: 100% 100%;
    box-sizing: border-box;
    .box_th {
      .box_con {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & > .syrqcard {
          width: 600px;
          height: 180px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          display: flex;
          align-items: center;
          text-align: left;
          margin-top: 40px;
          &:nth-child(2n) {
            margin-left: 50px;
          }
          & > div {
            padding-right: 30px;
          }
          img {
            width: 120px;
            height: 106px;
            padding: 0 15px 0 30px;
          }
          .title {
            line-height: 40px;
            font-size: 20px;
            font-weight: bold;
            color: #3f3f3f;
          }
          .data {
            height: 17px;
            font-size: 16px;
            font-weight: 400;
            color: #3f3f3f;
            line-height: 24px;
          }
        }
        .syrqcard {
          &:hover {
            box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
  .lastPage {
    padding-top: 130px;
    box-sizing: border-box;
    .box_fi {
      width: 100%;
      .title_fi {
        font-size: 30px;
        font-weight: bold;
        color: #3f3f3f;
        margin-bottom: 50px;
        @media screen and (max-height: 768px) {
          margin-bottom: 30px;
        }
        text-align: center;
      }
      .img_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 10%;
        .img_data {
          width: 216px;
          height: 144px;
          margin: 15px;
          overflow: hidden;
          background-color: #fff;
          img {
            width: 216px;
          }
        }
      }
    }
    .foo_box {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  @keyframes gt {
    0% {
      bottom: 0px;
      /*margin-left: 0px;*/
    }
    25% {
      bottom: 5px;
      /*margin-left: 10px;*/
    }
    50% {
      bottom: 10px;
      /*margin-left: 20px;*/
    }
    75% {
      bottom: 15px;
      /*margin-left: 10px;*/
    }
    100% {
      bottom: 20px;
      /*margin-left: 0px;*/
    }
  }
}
</style>
<style>
.el-notification.right {
  background: #2866e3;
  width: auto !important;
  overflow: visible;
  padding: 0 !important;
}
.el-notification__content {
  margin-top: 0 !important;
}
.wy-notify-div {
  width: 320px;
  height: 76px;
  background: #2866e3;
  color: #fff;
  /* width: 100%; */
  letter-spacing: 8px;
  position: relative;
  padding: 18px 0;
  text-align: center;
  cursor: pointer;
  /* padding: 18px 50px !important; */
  /* box-sizing: border-box; */
}
.wy-notify-div .wy-title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 8px !important;
}
.wy-san-jiao {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: -16px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 13.9px;
  border-color: transparent transparent transparent #2866e3;
}
.wy-txt {
  font-size: 14px;
  margin-top: 18px !important;
}
.wy-close {
  position: absolute;
  color: #3f3f3f;
  bottom: -24px;
  right: 0;
  font-size: 10px;
}
/* .onePage .el-notification__closeBtn{
  color: #fff;
  font-size: 14px;
} */
.change-class {
  border: none;
}
.change-class .el-notification__closeBtn {
  color: #fff !important;
  font-size: 16px !important;
}
</style>
<style lang="less">
.vedio_wrp {
  width: 52rem;
  height: 32rem;
  // width: 100%;
  // height: 30rem;
  // font-size: 0;
  // box-shadow: 0px 10px 9px 1px rgba(0, 0, 0, 0.08);
  // border-radius: 20px;
  // overflow: hidden;
  // position: relative;
  > .video {
    width: 100%;
    height: 100%;
    // z-index: 1;
    object-fit: fill;
    // border-radius: 20px;
    // overflow: hidden;
  }
  // > .mask {
  //   position: absolute;
  //   width: 100%;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   border-radius: 20px;
  //   overflow: hidden;
  //   cursor: pointer;
  //   > .play {
  //     width: 200px;
  //     height: 200px;
  //     z-index: 4;
  //     border-radius: 100%;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //   }
  // }
  // > .init_mask {
  //   z-index: 3;
  //   border-radius: 20px;
  //   overflow: hidden;
  //   > .init_bg {
  //     border-radius: 20px;
  //     overflow: hidden;
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;
  //     width: 100%;
  //   }
  // }
  // > .video_mask {
  //   z-index: 2;
  //   background: rgba(0, 0, 0, 0.4);
  // }
}
</style>